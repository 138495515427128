.about-me-list{
  display:grid;
  grid-template-columns:repeat(auto-fit,minmax(250px,1fr));
  grid-gap: 40px;
  flex-wrap:wrap;
  margin-top:50px;
}

.pic-info{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  line-height: 30px;
  margin-top: 30px;
  padding: 20px;
  border-radius: 10px;
  background-color:var(--dark-green);

  .pic-buttons{
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    .pic-button{
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;
      
      background-color: var(--white-color);
      color: var(--black-color);
      width: 35px;
      height: 35px;
      border-radius: 50px;
      margin: 0 0.5rem;
  
      &:hover{
        background-color: var(--black-color);
        color: var(--white-color);
      }
    }

  }
}

.about-text{
  margin-top: 10px;
}

img{
  width: 100%;
  border-radius: 15px;
}

.about-section {
  margin-bottom: 25px;
}

.related-skills{
  display:flex;
  flex-wrap: wrap;
}

.related-skills p{
  background-color: var(--white-color);
  padding: 10px;
  margin-right:10px;
  margin-top: 10px;
  border-radius:10px;
  font-size: 15px;
  font-weight: 400;
}
