#contact {
  padding: 20px 0;
  text-align:center;
  background-color: var(--dark-green);
}

.contact-socials{
  display: flex;
  align-items: center;
  justify-content:center;
  padding: 1rem;

  a {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--white-color);
    color: var(--black-color);
    margin: 0.5rem 0.25rem;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: var(--black-color);
      color: var(--white-color);
    }

    @media screen and (min-width: 2000px) {
      width: 70px;
      height: 70px;

      margin: 0.75rem 0.5rem;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}