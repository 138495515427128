.app__skills{
  margin-top: 30px;
  display:flex;
  flex-wrap: wrap;
}

.app__experiences{
  display:grid;
  grid-template-columns:repeat(auto-fit,minmax(250px,1fr));
  grid-gap:40px;
  margin-top:50px;
}

.app__exp-info{
  padding:40px;
  background:var(--dark-green);
  border-radius:10px;
  border-right: 10px;
}