:root {
  --font-base: sans-serif;

  --light-green: #bcd6dc;
  --dark-green: #87AAB3;
  --black-color: #19191b;
  --gray-color: #7e969c;
  --white-color: #F2FCFE;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}