.app__projects{
  display:grid;
  grid-template-columns:repeat(auto-fit,minmax(250px,1fr));
  grid-gap:40px;
  margin-top:50px;
}

.app__project-item{
  background: var(--dark-green);
  padding:40px;
  border-right:10px;
  border-radius:10px;
}